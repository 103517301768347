import { LoadingBackdrop as MuiLoadingBackdrop } from "@hkexpressairwayslimited/ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectLoadingBackdropCount, selectLoadingZIndex } from "../../selectors/loadingBackdropSelector";

export interface LoadingBackdropProps {}

export function LoadingBackdrop(props: LoadingBackdropProps) {
  const count = useSelector(selectLoadingBackdropCount);
  const zIndex = useSelector(selectLoadingZIndex);
  const isOpen = useMemo(() => {
    return count > 0;
  }, [count]);

  return <MuiLoadingBackdrop isOpen={isOpen} zIndex={zIndex} />;
}
