import i18next, { Resource, ResourceKey } from "i18next";
import detector, { DetectorOptions } from "i18next-browser-languagedetector";
import HttpApi, { HttpBackendOptions } from "i18next-http-backend";
import { fetchI18nContent } from "lib/common/service";
import { i18nBrowserLogger, i18nServerLogger } from "loggers";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LNG, I18NEXT_KEY, SUPPORTED_LANGUAGES_WITH_LOCALES } from "./conf";

export const i18nInit = (lang?: string, resources?: Resource, isAutomatedTest?: boolean) => {
  type PluginOptions = HttpBackendOptions & DetectorOptions;

  i18next
    .use(HttpApi)
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init<PluginOptions>({
      lng: isAutomatedTest ? "cimode" : lang,
      resources,
      partialBundledLanguages: true,
      debug: typeof window === "undefined" ? i18nServerLogger.enabled : i18nBrowserLogger.enabled,
      cleanCode: true,
      load: "currentOnly",
      lowerCaseLng: !SUPPORTED_LANGUAGES_WITH_LOCALES.find((lang) => lang.match(/[A-Z]/)),
      supportedLngs: SUPPORTED_LANGUAGES_WITH_LOCALES,
      backend: {
        loadPath: `https://-?lang={{lng}}`,
        request: (options, url, payload, callback) => {
          const params = new URL(url).searchParams;
          const lang = params.get("lang") || DEFAULT_LNG;

          if (isAutomatedTest) {
            callback(undefined, {
              status: 200,
              data: {},
            });
          } else {
            fetchI18nContent(lang)
              .then((res) => {
                if (res) {
                  callback(undefined, {
                    status: 200,
                    data: res.content as ResourceKey,
                  });
                } else {
                  throw new Error("No content found");
                }
              })
              .catch((err) => {
                callback(err, err);
              });
          }
        },
      },
      detection: {
        lookupCookie: I18NEXT_KEY,
      },
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });

  return i18next;
};

export const i18n = i18next;
