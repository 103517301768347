"use client";
import { Button, Font, Modal, ModalHandle } from "@hkexpressairwayslimited/ui";
import { Stack } from "@mui/material";
import { ErrorType } from "lib/features/flight-book/payment/definition";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanErrorModalDetail, selectErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";

export const DetailErrorModal = () => {
  const { t } = useTransContent();
  const { desc, title, detailsElm, details, ctaText, onClose, onConfirm, type, data } =
    useSelector(selectErrorModalDetail) ?? {};
  const [ref, setFef] = useState<ModalHandle | null>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (desc && ref) {
      ref.open();
    }
  }, [desc, ref]);
  return (
    type === ErrorType.D &&
    desc &&
    title && (
      <Modal
        disabledBackDropClose={true}
        noCrossIcon={true}
        title={t(title, data?.tReplaceData?.title)}
        onClose={() => {
          ref?.close();
          dispatch(cleanErrorModalDetail());
          onClose && onClose(data);
        }}
        ref={setFef}
      >
        <Stack rowGap={2} direction='column'>
          <Font>{t(desc, data?.tReplaceData?.desc)}</Font>
          {details && detailsElm && detailsElm(details)}
          <Button
            fullWidth
            onClick={() => {
              ref?.close();
              dispatch(cleanErrorModalDetail());
              onConfirm && onConfirm(data);
            }}
          >
            {(ctaText && t(ctaText)) || "Confirm"}
          </Button>
          {data?.trace_id && <input type='hidden' id='api_response_trace_id' value={data.trace_id} />}
        </Stack>
      </Modal>
    )
  );
};
